import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Euquire } from '../json/euquire';
import formImg1 from '../images/form-img1.webp';
import formImg2 from '../images/form-img2.webp';
import formImg3 from '../images/form-img3.webp';
import formImg4 from '../images/form-img4.webp';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import Successfullpopup from '../images/Successfullpopup.png';
import ErrorMessage from '../images/ErrorMessage.png';
import { FaRegUserCircle, FaRegEnvelope, FaPhoneAlt, FaMapMarkerAlt, FaEnvelopeOpenText } from 'react-icons/fa';

class EuquireSec extends Component {

	constructor(props) {
		super(props)
		this.state = {
			euquireJson: [],
			name: "",
			email: "",
			number: "",
			Company: "",
			message: "",
			file: null,
			successModel: false,
			ErrorModel: false,
			invalidFieldModal: false,
		}
	}

	componentDidMount() {
		Euquire.getEuquire().then((euquire, err) => {
			if (!err) {
				this.setState({
					euquireJson: euquire,
				});
			}
		});
	}


	submitForm = e => {

		if (this.state.name === '' || this.state.email === '' || this.state.number === '' || this.state.Company === '' || this.state.message === '') {
			alert('Please Fill Required All Fields');
		}
		else {
			let formData = new FormData()
			formData.set("your-name", this.state.name)
			formData.set("your-email", this.state.email)
			formData.set("number", this.state.number)
			formData.set("Companyname", this.state.Company)
			formData.set("your-message", this.state.message)
			formData.append("Document", this.state.file)
			// formdata.append("Image", { uri: photo.uri, name: 'image.jpg', type: 'image/jpeg' })
			axios.post('https://dev.iquincesoft.com/iqsandbox/wp-json/contact-form-7/v1/contact-forms/1675/feedback', formData, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
				.then(res => {
					if(res.data.status === "mail_sent"){
						this.setState({
							name: "",
							email: "",
							number: "",
							Company: "",
							message: "",
							file: null,
							successModel: true
						})
						setTimeout(() => {
							window.location.reload()
						}, 2000)
					}
				}).catch(err => {
					console.log("err--->"+err)
					this.setState({
						ErrorModel: true
					})
				})
		}
	}

	render() {
		const { euquireJson } = this.state;
		return (
			<>
				<div className="euquire-sec" id="EuquireSec">
					<div className="container">
						<div className="row" >
							<div className="col-lg-1"></div>
							<div className="col-lg-5 col-md-6">
								<div className="form-box">
									{euquireJson.length && euquireJson.map(euquireJsons => {
										return (
											<div key={euquireJsons.id}>
												<h3 dangerouslySetInnerHTML={{ __html: euquireJsons.name }}></h3>
												<p dangerouslySetInnerHTML={{ __html: euquireJsons.content }}></p>
											</div>
										);
									})}
									<ul>
										<li><img alt="Design" title="Design" src={formImg4} /> Design</li>
										<li><img alt="Code" title="Code" src={formImg1} /> Code</li>
										<li><img alt="Test" title="Test" src={formImg3} /> Test</li>
										<li><img alt="Speed Up" title="Speed Up" src={formImg2} /> Speed Up</li>
									</ul>
								</div>
							</div>

							<div className="col-lg-5 col-md-6">
								<Modal
									onClose={() => this.setState({
										successModel: false
									})}
									open={this.state.successModel}
									style={{
										position: 'relative',
										boxShadow: '2px solid black',
										height: 540,
										width: 540,
										margin: 'auto',
										color: '#fff',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontSize: 10
									}}
								>
									<img style={{ outline:'none'}}src={Successfullpopup} alt="Successfullpopup" />
								</Modal>
								<Modal
									onClose={() => this.setState({
										ErrorModel: false
									})}
									open={this.state.ErrorModel}
									style={{
										position: 'relative',
										boxShadow: '2px solid black',
										height: 540,
										width: 540,
										margin: 'auto',
										color: '#fff',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontSize: 10
									}}
								>
									<img alt="img" width="80%" src={ErrorMessage} />
								</Modal>



								<form style={{ position: 'relative' }}>
									<div className="in-box"><FaRegUserCircle /> <input onChange={e => this.setState({ name: e.target.value })} value={this.state.name} className="box" type="text" name="" placeholder="Name" /></div>
									<div className="in-box"><FaRegEnvelope /> <input onChange={e => this.setState({ email: e.target.value })} value={this.state.email} className="box" type="text" name="" placeholder="Work e-Mail*" /></div>
									<div className="in-box"><FaPhoneAlt /> <input onChange={e => this.setState({ number: e.target.value })} value={this.state.number} className="box" type="text" name="" placeholder="Phone Number" /></div>
									<div className="in-box"><FaMapMarkerAlt /> <input onChange={e => this.setState({ Company: e.target.value })} value={this.state.Company} className="box" type="text" name="" placeholder="Company" /></div>
									<div className="in-box"><FaEnvelopeOpenText /> <textarea onChange={e => this.setState({ message: e.target.value })} value={this.state.message} className="box" rows="3" placeholder="Message / Response*"></textarea></div>
									<label style={{ fontWeight: "600", borderBottom: "1px solid black" }} for="files">Attach files if any</label>
									<input style={{ visibility: "hidden" }} title="Choose a Resume please" id="files" name="files" type="file" accept=".png, .jpg, .pdf" placeholder="Attach files if any." onChange={e => this.setState({
										file: e.target.files[0]
									})} />
									<Link onClick={() => this.submitForm()} to="#">Enquire Now</Link>
								</form>
							</div>
						</div>
					</div>
				</div >
			</>
		);
	}
}

export default EuquireSec;